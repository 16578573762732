<template>
  <div>
    <TopHeader />
    <div class="h-20 bg-white"></div>
    <img src="/images/bg-banner.jpg" alt="" class="w-full" />
    <div class="py-10 bg-nude-1">
      <div class="container">
        <h1 class="text-4xl font-bold leading-tight text-center md:leading-normal md:text-5xl">
          Aluminium Kitchen <br class="hidden md:block"> Cabinet in Malaysia
        </h1>
      </div>  
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader.vue";

export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TopHeader,
  },
};
</script>

<style>
.screenSize {
  min-height: 700px;
}

@media (width: 768px) {
  .screenSize {
    min-height: 750px;
  }
}
@media (width: 1024px) {
  .screenSize {
    min-height: 830px;
  }
}
@media (width: 1280px) {
  .screenSize {
    min-height: 870px;
  }
}
</style>
