<template>
  <div>
    <WhatsappButton />
    <MainBanner />

    <!-- product section  -->
    <div id="whatwedo" class="py-10 bg-black-1 lg:pb-0">
      <div class="container">
        <h2 class="text-2xl font-semibold text-center text-white">
          What We Do
        </h2>
        <div class="bg-yellow-800 w-10 h-0.5 mx-auto mt-3"></div>
      </div>

      <!-- product list -->
      <div class="px-4 mt-8 lg:px-0">
        <div class="md:flex md:flex-wrap">
          <div
            v-for="(item, i) in product"
            :key="i"
            class="pb-4 lg:pb-0 md:px-2 md:w-1/2 lg:w-1/5 lg:px-0"
          >
            <div class="relative overflow-hidden imgHover">
              <img
                :src="'/images/' + item.image + '.jpg'"
                :alt="item.alt"
                class=""
              />
              <div class="bg-opacity-60 overlay bg-black-1"></div>
            </div>
            <!-- <div
              class="absolute top-0 left-0 w-full h-full transition duration-300 bg-opacity-20 bg-black-1 hover:bg-opacity-80"
            >
              <p
                class="relative text-2xl font-semibold text-white lg:text-lg top-7 left-4"
              >
                {{ item.alt }}
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- product comparisson -->
    <div id="products" class="py-10 bg-nude-1">
      <div class="container">
        <h2 class="text-2xl font-semibold text-center text-black-1">
          Product Differentiation
        </h2>
        <div class="bg-gray-500 w-10 h-0.5 mx-auto mt-3"></div>

        <div class="mt-6">
          <div
            v-for="(item, i) in compare"
            :key="i"
            class="pb-5 lg:pb-10 lg:flex lg:items-center"
          >
            <div class="md:w-2/3 lg:w-1/2 md:mx-auto" :class="item.order">
              <img :src="'/images/' + item.image + '.jpg'" :alt="item.alt" />
            </div>
            <div class="mt-5 text-center md:mx-auto md:w-2/3 lg:w-1/2 lg:px-2">
              <div
                class="px-5 py-10 transition duration-200 ease-linear transform rounded-md shadow-md hover:scale-105"
                :class="item.bg"
              >
                <p class="text-xl font-bold">{{ item.alt }}</p>
                <div
                  v-for="list in item.sublist"
                  :key="list"
                  class="flex items-center pt-5 pl-4 text-lg"
                >
                  <div class="w-6">
                    <img :src="/images/ + list.icon + '.png'" alt="" class="" />
                  </div>
                  <p class="pl-3">{{ list.p }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- location -->
    <div
      id="showroom"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-location.jpg')"
    >
      <div class="container py-20">
        <div class="p-6 md:w-4/5 lg:w-5/6 md:mx-auto bg-black-1 bg-opacity-90">
          <h2
            class="text-2xl font-medium text-center text-white capitalize md:text-3xl"
          >
            Visit Us To See The Differences
          </h2>
          <div class="bg-white w-10 h-0.5 mx-auto mt-3"></div>
          <div class="lg:flex lg:pt-6">
            <div
              class="flex py-6 text-white border-b border-gray-200 lg:px-4 lg:border-b-0 lg:border-r lg:w-1/2"
            >
              <div class="w-2/12">
                <img src="/images/loc-1.png" alt="" class="" />
              </div>
              <div class="w-10/12 pl-3 text-base">
                <p class="font-semibold">SHOWROOM</p>
                <div class="inline-flex">
                  <i class="pr-2 text-xl fa-brands fa-waze"></i>
                  <p>
                    <a
                      href="https://www.waze.com/en/live-map/directions/my/selangor/puchong/38,-jalan-industri-mas-4?place=ChIJJdoW2MWzzTERhTOXckt4jNk"
                      class="block pt-1"
                    >
                    38, Jalan Industrian Mas 4, Taman Mas Puchong, 47100 Puchong, Selangor
                    </a>
                  </p>
                </div>
                <p class="pt-2 font-semibold">*By Appointment Only</p>
                <!--  -->
              </div>
            </div>
            <div class="flex py-5 text-white lg:px-4 lg:border-b-0 lg:w-1/2">
              <div class="w-2/12">
                <img src="/images/loc-1.png" alt="" class="" />
              </div>
              <div class="w-10/12 pl-3 text-base">
                <p class="font-semibold">FACTORY</p>
                <div class="inline-flex">
                  <i class="pr-2 text-xl fa-brands fa-waze"></i>
                  <p>
                    <a
                      href="https://www.waze.com/en/live-map/directions/my/selangor/puchong/22,-jalan-industri-mas-8?place=ChIJ7_V2b8-zzTERGfE_NeSsCsI"
                    >
                    22, Jalan Industrian Mas 8, Taman Mas Puchong, 47100 Puchong, Selangor
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- phone number -->
          <div
            class="pt-5 text-base font-semibold text-center text-white lg:text-lg"
          >
            <div class="flex justify-center lg:items-center">
              <div>
                <i class="pr-2 text-xl fa-solid fa-phone"></i>
                <i class="pr-2 text-xl fa-brands fa-whatsapp"></i>
              </div>
              <p class="">
                <a href="https://wa.me/60193136406">019-313 6406 (Joseph)</a> 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10 bg-black-1">
      <div class="container">
        <h2 class="text-3xl font-semibold text-center text-white">
          Why Choose Us
        </h2>

        <div class="lg:max-w-3xl lg:mx-auto">
          <div class="mt-6 md:flex md:flex-wrap">
            <div
              v-for="(item, i) in choose_us"
              :key="i"
              class="mb-4 md:px-1 md:w-1/3"
            >
              <div class="relative">
                <img
                  :src="'/images/' + item.bgImg + '.jpg'"
                  alt=""
                  class="lg:w-full"
                />
                <div
                  class="absolute top-0 w-full h-full bg-black bg-opacity-60"
                >
                  <div
                    class="absolute w-full px-4 text-center text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                  >
                    <img
                      :src="'/images/' + item.icon + '.png'"
                      :alt="item.alt"
                      class="w-20 mx-auto lg:w-12"
                    />
                    <p class="pt-3 text-lg md:leading-tight md:h-10">
                      {{ item.alt }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- testimonials -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h2 class="text-3xl font-semibold text-center text-brown-2">
          Our Reviews
        </h2>
        <div class="bg-yellow-800 w-10 h-0.5 mx-auto mt-3"></div>
        <Testimonials class="pt-6" />
      </div>
    </div>

    <!-- Gallery -->
    <div id="gallery" class="py-10 bg-nude-1">
      <div class="container">
        <h2 class="text-3xl font-semibold text-center text-brown-2">
          Our Works
        </h2>
        <div class="bg-yellow-800 w-10 h-0.5 mx-auto mt-3"></div>
        <div class="mt-8">
          <hooper :settings="hooperSettings" class="h-full">
            <slide
              v-for="(slide, indx) in gallery"
              :key="indx"
              :index="indx"
              class="px-2"
            >
              <img :src="'/images/' + slide + '.jpg'" alt="" />
            </slide>
            <hooper-navigation slot="hooper-addons"></hooper-navigation>
          </hooper>
        </div>
        <div class="mt-8">
          <h2 class="pb-3 text-3xl font-semibold text-center text-brown-2">
            Our Past Projects
          </h2>
          <hooper :settings="hooperSettings" class="h-full">
            <slide
              v-for="(item, indx) in shorts"
              :key="indx"
              :index="indx"
              class="px-2"
            >
              <iframe
                width="100%"
                height="600"
                :src="'https://www.youtube.com/embed/' + item"
                title="1 August 2023"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </slide>
            <hooper-navigation slot="hooper-addons"></hooper-navigation>
          </hooper>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div
      id="element"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')"
    >
      <div class="container lg:flex lg:items-center lg:justify-between">
        <div class="pt-5 lg:w-1/3">
          <img
            src="/images/main-logo.png"
            alt=""
            class="mx-auto w-60 lg:mx-0 md:w-80"
          />
          <div class="pt-3 text-center text-white lg:text-left">
            <h1 class="pb-2 text-lg font-bold md:text-xl">Cubetiq Sdn Bhd</h1>
            <p class="pt-5 text-lg font-semibold">Showroom</p>
            <div class="inline-flex">
              <i class="pr-2 text-2xl fa-brands fa-waze"></i>
              <p class="text-base text-left">
                <a
                  href="https://www.waze.com/en/live-map/directions/my/selangor/puchong/38,-jalan-industri-mas-4?place=ChIJJdoW2MWzzTERhTOXckt4jNk"
                >
                38, Jalan Industrian Mas 4, Taman Mas Puchong, 47100 Puchong, Selangor
                </a>
              </p>
            </div>
            <p class="pt-2 font-semibold">*By Appointment Only</p>

            <p class="pt-5 text-lg font-semibold">Factory</p>
            <div class="inline-flex">
              <i class="pr-2 text-2xl fa-brands fa-waze"></i>
              <p class="text-base text-left">
                <a
                  href="https://www.waze.com/en/live-map/directions/my/selangor/puchong/22,-jalan-industri-mas-8?place=ChIJ7_V2b8-zzTERGfE_NeSsCsI"
                >
                22, Jalan Industrian Mas 8, Taman Mas Puchong, 47100 Puchong, Selangor
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="pt-5 text-center text-white lg:text-right lg:w-1/2">
          <div class="flex justify-center lg:text-lg lg:items-center">
            <div>
              <i class="pr-2 text-xl fa-solid fa-phone"></i>
              <i class="pr-2 text-xl fa-brands fa-whatsapp"></i>
            </div>
            <p class="">
              <a href="https://wa.me/60193136406">019-313 6406 (Joseph)</a> 
            </p>
          </div>

          <div class="flex justify-center mt-5 space-x-2">
            <div class="w-10">
              <a href="https://www.facebook.com/profile.php?id=100057520266236">
                <img src="/images/facebook.png" alt="" class="" />
              </a>
            </div>

            <div class="w-10">
              <a
                href="https://www.xiaohongshu.com/user/profile/5fbfb5e60000000001007da8"
              >
                <img src="/images/xhs.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-3 bg-black-1">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from "@/components/MainBanner.vue";
import WhatsappButton from "@/components/WhatsappButton.vue";
import Testimonials from "@/components/Testimonials.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "Home",
  components: {
    MainBanner,
    WhatsappButton,
    Testimonials,
    Hooper,
    Slide,
    HooperNavigation,
},
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        autoPlay: true,
        infiniteScroll: true,
        hoverPause: true,
        wheelControl: false,
        playSpeed: 5000,
        breakpoints: {
          767: {
            centerMode: false,
            itemsToShow: 2,
          },
          1000: {
            itemsToShow: 3,
            centerMode: false,
          },
        },
      },
      product: [
        { image: "product-1", alt: "Aluminum Kitchen Cabinet" },
        { image: "product-2", alt: "Built in Wardrobe" },
        { image: "product-3", alt: "TV Cabinet" },
        { image: "product-4", alt: "Shoe Cabinet" },
        { image: "product-5", alt: "Artisan Panel" },
      ],
      // compare: [
      //   {
      //     padd: "pb-10 md:pb-0",
      //     bgColor: "bg-black-1 text-white",
      //     alt: "Cubetiq Aluminum Kitchen",
      //     p1: "Premium Design Series",
      //     image: "compare-1",
      //     sublist: [
      //       {
      //         image: "check",
      //         text: "Premium Finishing",
      //       },
      //       {
      //         image: "check",
      //         text: "Designer Kitchen",
      //       },
      //       {
      //         image: "check",
      //         text: "10+ Colors",
      //       },
      //     ],
      //   },
      //   {
      //     padd: "pt-10 md:pt-0",
      //     bgColor: "bg-white text-black-1",
      //     alt: "Traditional Kitchen",
      //     p1: "Standard Design",
      //     image: "compare-2",
      //     sublist: [
      //       {
      //         image: "delete",
      //         text: "Premium Finishing",
      //       },
      //       {
      //         image: "delete",
      //         text: "Designer Kitchen",
      //       },
      //     ],
      //   },
      // ],
      choose_us: [
        {
          bgImg: "choose-6",
          icon: "icon-6",
          alt: "Direct From Factory",
        },
        {
          bgImg: "choose-7",
          icon: "icon-7",
          alt: "0% Credit Card Instalment",
        },
        {
          bgImg: "choose-2",
          icon: "icon-2",
          alt: "Anti Termite",
        },
        {
          bgImg: "choose-1",
          icon: "icon-1",
          alt: "100% Waterproof",
        },
        {
          bgImg: "choose-4",
          icon: "icon-4",
          alt: "Fire Prevention",
        },
        {
          bgImg: "choose-3",
          icon: "icon-3",
          alt: "Non-Formaldehyde",
        },
      ],
      gallery: [
        "gallery-1",
        "gallery-2",
        "gallery-3",
        "gallery-4",
        "gallery-5",
        "gallery-6",
        "gallery-7",
        "gallery-8",
      ],
      compare: [
        {
          bg: "bg-black-1 text-white",
          image: "compare-1",
          alt: "Cubetiq Aluminium Kitchen",
          sublist: [
            {
              icon: "check",
              p: "Premium Interior Carcass",
            },
            {
              icon: "check",
              p: "Durable",
            },
            {
              icon: "check",
              p: "Stylish Design",
            },
            {
              icon: "check",
              p: "Lightweight",
            },
          ],
        },
        {
          order: "lg:order-2",
          bg: "bg-white text-gray-600",
          image: "compare-2",
          alt: "Other Aluminium Kitchen",
          sublist: [
            {
              icon: "delete",
              p: "Joint Carcass",
            },
            {
              icon: "delete",
              p: "More Difficult to Clean",
            },
            {
              icon: "delete",
              p: "Old Fashioned",
            },
          ],
        },
      ],
      shorts: [
        "rCD_KzD_Y0s",
        "ilQKVMTnYF4",
        "_tPHKofrWQM",
        "kPaZq7TXTYo",
        "azT99w5Cme4",
      ],
    };
  },
};
</script>
<style>
.hooper-next,
.hooper-prev {
  background-color: rgba(255, 255, 255, 0.616);
  top: 50%;
}

img {
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.imgHover:hover img {
  transform: scale(1.1); /* Adjust the zoom level as desired */
}

.imgHover:hover .overlay {
  opacity: 1;
}
</style>
