<template>
  <div>
    <div class="mt-6">
      <carousel
        :autoplay="true"
        :loop="true"
        :autoplaySpeed="2500"
        :autoplayTimeout="6000"
        :responsive="{
          0: { items: 1, nav: false, dots: true },
          767: { items: 2, nav: false, dots: true },
          1000: { items: 3, nav: false, dots: true },
        }"
      >
        <div v-for="(item, i) in testimonials" :key="i" class="px-1">
          <div class="relative p-5 rounded-md bg-gray-1">
            <div class="pt-3">
              <p class="h-32 text-sm text-gray-600">
                {{ item.p }}
              </p>
            </div>
            <div class="pt-4">
              <p class="text-lg font-semibold">{{ item.client }}</p>
              <div class="text-yellow-400">
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
              </div>
            </div>
            <div class="absolute w-20 bottom-6 right-4">
              <img src="/images/google-review.png" alt="" class="" />
            </div>
          </div>
        </div>
      </carousel>
    </div>
    <div class="hidden">
      <img src="/images/bg-testimonial.jpg" alt="" />
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      testimonials: [
        {
          p: "Superior quality, first-rate workmanship, equitable price. Owner itself extremely accommodating and attentive to details. Strongly recommend.",
          client: "Kurt Yong",
        },
        {
          p: " Very Modern and trendy design for kitchen cabinet. My family love it so much. Thks so much",
          client: "Tung Chong Wei",
        },
        {
          p: "Very good price and after sales service. Keep it up",
          client: "KK Choong",
        },
        {
          p: "Nice service and good quality products! Strongly recommended for Aluminium Wardrobe!",
          client: "Fred Lee -",
        },
        {
          p: "Good services & good quality. Will recommend to friends",
          client: "Chok Siow Chuen",
        },
        {
          p: "Very professional and helpful.I am so satisfied with my cabinets.Highly recommended",
          client: "Scarlett Got",
        },
        {
          p: "Very professional and reasonable price. Keep it up",
          client: "Brandon Wong",
        },
      ],
    };
  },
};
</script>
